import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { canActivateAuthGuard } from './shared/services/auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      environment.isDemo
        ? import(
            './shared/components/theme/theme-demo-border/theme-demo-border.component'
          ).then(c => c.ThemeDemoBorderComponent)
        : import(
            './shared/components/theme/theme-dev/theme-dev.component'
          ).then(c => c.ThemeDevComponent),
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/landing/landing.module').then(
            m => m.LandingModule
          ),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./components/login/login.module').then(m => m.LoginModule),
      },
      {
        path: 'sso',
        loadChildren: () =>
          import('./components/login/sso/sso.module').then(m => m.SsoModule),
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./components/auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: 'onboarding/dta/:agencyId',
        loadComponent: () =>
          import(
            './shared/components/theme/theme-onboarding/theme-onboarding.component'
          ).then(m => m.ThemeOnboardingComponent),
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './components/onboarding/dta/onboarding-dta-overview/onboarding-dta-overview.component'
              ).then(m => m.OnboardingDtaOverviewComponent),
          },
        ],
      },
      {
        path: '',
        canActivate: [canActivateAuthGuard],
        children: [
          {
            path: 'agency',
            loadChildren: () =>
              import('./components/agency/agency.module').then(
                m => m.AgencyModule
              ),
          },
          {
            path: 'agent',
            loadChildren: () =>
              import('./components/agent/agent.module').then(
                m => m.AgentModule
              ),
          },
          {
            path: 'authority',
            loadChildren: () =>
              import('./components/authority/authority.module').then(
                m => m.AuthorityModule
              ),
          },
          {
            path: 'publisher',
            loadChildren: () =>
              import('./components/publisher/publisher.routes').then(
                m => m.publisherRoutes
              ),
          },
          {
            path: 'book-of-business',
            loadChildren: () =>
              import(
                './components/book-of-business/book-of-business.module'
              ).then(m => m.BookOfBusinessModule),
          },
          {
            path: 'carrier',
            loadChildren: () =>
              import('./components/carrier/carrier.module').then(
                m => m.CarrierModule
              ),
          },
          {
            path: 'consumer',
            loadChildren: () =>
              import('./components/consumer/consumer.module').then(
                m => m.ConsumerModule
              ),
          },
          {
            path: 'consumer/:consumerId',
            loadChildren: () =>
              import('./components/consumer/consumer.module').then(
                m => m.ConsumerModule
              ),
          },
          {
            path: 'employee',
            loadChildren: () =>
              import('./components/employee/employee.module').then(
                m => m.EmployeeModule
              ),
          },
          {
            path: 'lead-seller',
            loadChildren: () =>
              import('./components/lead-seller/lead-seller.module').then(
                m => m.LeadSellerModule
              ),
          },
          {
            path: 'settings',
            loadChildren: () =>
              import('./components/settings/settings.module').then(
                m => m.SettingsModule
              ),
          },
          {
            path: 'questionnaire',
            loadChildren: () =>
              import(
                './shared/components/questionnaire/questionnaire.module'
              ).then(m => m.QuestionnaireModule),
          },
          {
            path: 'subscriptions',
            loadChildren: () =>
              import('./components/subscriptions/subscriptions.routes').then(
                m => m.subscriptionsRoutes
              ),
          },
          {
            path: 'user',
            loadChildren: () =>
              import('./components/user/user.module').then(m => m.UserModule),
          },
        ],
      },
    ],
  },
];

// if (true || !environment.production) {
// eslint-disable-next-line no-constant-condition
if (routes[0].children) {
  if (!environment.production) {
    routes[0].children.push({
      path: 'dev-components',
      loadChildren: () =>
        import('./components/dev-components/dev-components.module').then(
          m => m.DevComponentsModule
        ),
    });
  }

  if (environment.isDemo || !environment.production) {
    routes[0].children.push({
      path: 'demo',
      loadChildren: () =>
        import('./components/demo/demo.module').then(m => m.DemoModule),
    });
  }

  routes[0].children.push({
    path: '**',
    loadChildren: () =>
      import('./components/error/error.module').then(m => m.ErrorModule),
  });
}

// @NgModule({
//   imports: [
//     RouterModule.forRoot(routes, {
//       initialNavigation: 'enabledBlocking',
//     }),
//   ],
//   exports: [RouterModule],
// })
// export class AppRoutingModule {}
